/*jslint vars: true, plusplus: true, devel: true, nomen: true, indent: 4, maxerr: 50 */
(function(){
	'use strict';

	window.onbeforeunload = function () {
	  window.scrollTo(0, 0);
	}

	$( document ).ready(function() {

		// force scroll top after reload
		$(document).scrollTop(0);
		
		AOS.init();

		$(window).scroll(function () {

			// Move logo on scroll
		    var scrollTop = $(window).scrollTop();
		    var height = 500 //$(window).height();

		    $('#site-logo h1').css({
		        'opacity': ((height - scrollTop) / height)
		    }); 

		});

		//Sticky header

		$(window).scroll(function(){
			var windowHeight = $(window).height() - 60;
		    if ($(window).scrollTop() > windowHeight) {
		        $('nav').addClass('fixed-header');
		    }
		    else {
		        $('nav').removeClass('fixed-header');
		    }
		});

		// Slick slider

		$('.slider-nav').slick({
			slidesToShow: 3,
			slidesPerRow: 3,
			slidesToScroll: 1,
			asNavFor: '.slider-for',
			dots: false,
			prevArrow: '<i class="icon-caret-left"></i>',
			nextArrow: '<i class="icon-caret-right"></i>',
			centerMode: false,
			focusOnSelect: true,
			draggable: true,
			easing: 'ease-in',
			adaptiveHeight: true,
			variableWidth: true
		});

		$('.slider-for').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			fade: true,
			asNavFor: '.slider-nav',
			cssEase: 'ease-in',
			adaptiveHeight: true
		});

		// Show/hide Portfolio info

		$(".portfolio-item .slider-nav .slide").not(".close").click(function(){
			$(".portfolio-item").removeClass("active-item");
			
			$(this).parent().parent().parent().parent().addClass("active-item");

			$(".portfolio-item.active-item .slider-for").animate({
			    "max-height": "999px"
			}, 500, function() {
			    // Animation complete.
			});

			$([document.documentElement, document.body]).animate({
				scrollTop: $(this).offset().top - 60
			}, 500, function() {

	      	});
		});


		$(".portfolio-item .close").click(function() {
			$(".portfolio-item").removeClass("active-item");

			$(".portfolio-item .slider-for").animate({
			    "max-height": "0px"
			}, 500, function() {
			    // Animation complete.
			});
		});

		$(".portfolio-item:first-child").addClass("first-item");

		$(".portfolio-item:first-child").hover(function() {
			$(this).removeClass("first-item");
		});


		var forEach=function(t,o,r){if("[object Object]"===Object.prototype.toString.call(t))for(var c in t)Object.prototype.hasOwnProperty.call(t,c)&&o.call(r,t[c],c,t);else for(var e=0,l=t.length;l>e;e++)o.call(r,t[e],e,t)};

		var hamburgers = document.querySelectorAll(".hamburger");
		if (hamburgers.length > 0) {
		  forEach(hamburgers, function(hamburger) {
		    hamburger.addEventListener("click", function() {
		      this.classList.toggle("is-active");
		    }, false);
		  });
		}

		$(".hamburger").click(function(){
			$("#menu-main-menu").slideToggle();
		});

	});

})(); // END OF USE STRICT FUNCTION